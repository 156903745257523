// Used to get roles of user. Data after '=' only a-z|0-9
export enum ERolesType {
	all = 'all',
	servicedesk_profile = 'servicedeskprofile',
	servicedesk_userprofile = 'servicedeskuserprofile',
	sub_acr_upload_empty_containers = 'subacruploademptycontainers',
	sub_capacity_at = 'subcapacityat',
	sub_capacity_customer = 'subcapacitycustomer',
	sub_capacity_ddr = 'subcapacityddr',
	sub_capacity_dedicated = 'subcapacitydedicated',
	sub_capacity_fullaccess = 'subcapacityfullaccess',
	sub_capacity_proc = 'subcapacityproc',
	sub_costing_at = 'subcostingat',
	sub_costing_fullaccess = 'subcostingfullaccess',
	sub_costing_ger = 'subcostingger',
	sub_costing_proc = 'subcostingproc',
	sub_costing_term = 'subcostingterm',
	sub_customer_fullaccess = 'subcustomerfullaccess',
	sub_customer_intermodal = 'subcustomerintermodal',
	sub_customer_operational = 'subcustomeroperational',
	sub_generalregister_imd = 'subgeneralregisterimd',
	sub_general_register_nickname = 'subgeneralregisternickname',
	sub_insurance_claims = 'subinsuranceclaims',
	sub_insurance_gr = 'subinsurancegr',
	sub_locality_region = 'sublocalityregion',
	sub_vendor_fullaccess = 'subvendorfullaccess',
	sub_vendor_proc = 'subvendorproc',
	sub_vessel_main_data = 'subvesselmaindata',
	sub_transshipment_registration = 'subtransshipmentregistration',
	sub_proforma_port_terminals = 'subproformaportterminals',
	sub_proforma_cutoffs_availabilities = 'subproformacutoffsavailabilities',
	sub_proforma_allocation = 'subproformaallocation',
	sub_proforma_vessels = 'subproformavessels',
	sub_proforma_ports_vessels_capacity = 'subproformaportsvesselscapacity',
	sub_schedule_port_terminals = 'subscheduleportterminals',
	sub_product_characteristics = 'subproductcharacteristics',
	sub_product_product = 'subproductproduct',
	sub_product_instances = 'subproductinstances',
	sub_product_adhoc_approver = 'subproductadhocapprover',
	sub_product_adhoc = 'subproductadhoc',
	sub_schedule_cut_offs_availabilities = 'subschedulecutoffsavailabilities',
	sub_schedule_allocation = 'subscheduleallocation',
	sub_commercial_agreement_visualize = 'subcommercialagreementvisualize',
	sub_commercial_agreement_management = 'subcommercialagreementmanagement',
	sub_commercial_agreement_creation = 'subcommercialagreementcreation',
	sub_commercial_agreement_edit = 'subcommercialagreementedit',
	sub_pricing_creation = 'subpricingcreation',
	sub_pricing_management = 'subpricingmanagement',
	sub_pricing_instancemanagement = 'subpricinginstancemanagement',
	sub_costmodule_visualize = 'subcostmodulevisualize',
	sub_costmodule_edit = 'subcostmoduleedit',
	sub_generalregister_disclaimers = 'subgeneralregisterdisclaimers',
	sub_generalregister_exchangerate_management = 'subgeneralregisterexchangeratemanagement',
	sub_generalregister_exchangerate_management_creation = 'subgeneralregisterexchangeratemanagementcreation',
	sub_pricing_agreement_management = 'subpricingagreementmanagement',
	sub_pricing_agreement_management_pricingteam = 'subpricingagreementmanagementpricingteam',
	sub_pricing_agreement_management_pricingcoordination = 'subpricingagreementmanagementpricingcoordination',
	sub_pricing_agreement_management_pricingmanager = 'subpricingagreementmanagementpricingmanager',
	sub_pricing_pricingarchitecture_approval = 'subpricingpricingarchitectureapproval',
	sub_pricing_agreement_management_expire = 'subpricingagreementmanagementexpire',
	sub_pricing_agreement_management_postpone = 'subpricingagreementmanagementpostpone',
	sub_cost_exception_creation = 'subcostexceptioncreation',
	sub_cost_exception_management = 'subcostexceptionmanagement',
	sub_customer_financial = 'subcustomerfinancial',
	sub_vessel = 'subvessel',
	sub_general_register_terminals = 'subgeneralregisterterminals',
	sub_generalregister_ports = 'subgeneralregisterports',
	sub_generalregister_citiesandstates = 'subgeneralregistercitiesandstates',
	sub_proforma = 'subproforma',
	sub_proforma_doc_deadline = 'subproformadocdeadline',
	sub_schedule = 'subschedule',
	sub_schedule_doc_deadline = 'subscheduledocdeadline',
}